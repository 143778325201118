export default [
  {
    path: '/approve-timesheet',
    name: 'approve-timesheet',
    component: () => import('@/views/admin-timesheet/index.vue'),
    meta: {
      noAuth: true,
      title: 'Timesheets',
    },
  },
];
