export default [
    {
        path: '/payments/:id/invoice',
        name: 'payments.invoice',
        component: () => import('@/views/payments/invoice.vue'),
        meta: {
            noAuth: true,
            title: 'Payments',
        },
    },
    {
      path: '/payments',
      name: 'payments',
      component: () => import('@/views/payments/index.vue'),
      meta: {
        noAuth: true,
        title: 'Payments',
      },
    }/*,
    {
        path: '/invoice',
        name: 'payments',
        component: () => import('@/views/payments/invoice.vue'),
        meta: {
            noAuth: true,
            title: 'Payments',
        },
    },*/
  ]
