export default [
    {
        path: '/chat',
        name: 'chat',
        component: () => import('@/views/chat/chat.vue'),
        meta: {
            noAuth: true,
            title: 'Chat',
        },
    },
]
  
