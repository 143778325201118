export default [
  {
    path: '/staff-bank/:id/practice/:practice',
    component: () => import('@/views/staff-bank/staff-profile/profile.vue'),
    name: 'staff-bank.single',
    meta: {
      noAuth: true,
      title: 'My Account',
    },
  },
  {
    path: '/staff-bank',
    name: 'staff-bank',
    component: () => import('@/views/staff-bank/index.vue'),
    meta: {
      noAuth: true,
      title: 'Staff Bank',
    },
  },
];
