import Vue from 'vue';
import VueRouter from 'vue-router';
import dashboardRoute from './modules/dashboard_route'
// import errorRoute from './modules/error_route'
import programmeRoute from './modules/programmes_route';
import locationRoute from './modules/locations_route';
import profileRoute from './modules/profile_route';
import staff_bank_route from '@/router/modules/staff_bank_route';
// import patientRoute from './modules/patient_route'
// import itemRoute from './modules/item_route'
// import unitRoute from './modules/unit_route'
// import membersRoute from './modules/members_route'
import schedulerRoute from './modules/calendar_route';
import paymentRoute from './modules/payment_route';
import statisticRoute from './modules/statistics_route';
import auth_route from '@/router/modules/auth_route';
import settings_route from '@/router/modules/settings_route';
import chat_route from '@/router/modules/chat_route';
import network_route from './modules/network_route';
import timesheet_route from './modules/timesheet_route';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    ...schedulerRoute,
    ...programmeRoute,
    ...locationRoute,
    ...profileRoute,
    ...paymentRoute,
    ...statisticRoute,
    ...staff_bank_route,
    ...auth_route,
    ...settings_route,
    ...chat_route,
    ...network_route,
    ...timesheet_route,
    ...dashboardRoute,
    // ...patientRoute,
    // ...itemRoute,
    // ...unitRoute,
    // ...errorRoute,
    // ...membersRoute,
  ],
});

export default router;
