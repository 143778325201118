import api from '../axios';

export default {
  login: (payload) => api.post('/clinicians/login'),
  logout: (payload) => api.post('/logout'),
  getCurrentUser: payload => api.get('/api/profile'),
  getCurrentUserDetail: () => api.get('/api/profile'),
  getNotifications: () => api.get(`/api/pcn/notifications`),
  updateNotifications: (id) => api.put(`/api/pcn/notifications/${id}`),
  getAllPractice: (payload) => api.get('/api/practices'),
  getAllPcn: (payload) => api.get('/api/pcn'),
};
