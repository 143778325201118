import axios from 'axios'
import config from '@/constants/config'
import Cookies from 'js-cookie'
import { loginAppUrl } from '@/constants/config';


const axiosIns = axios.create({
  baseURL: config.apiUrl,
    withCredentials:true
})

axiosIns.interceptors.request.use(function (config) {

        config.params ={'auth_pcn_id':`${sessionStorage.getItem('auth_pcn_id')}`}
        return config;
    }, error => Promise.reject(error),

    axiosIns.interceptors.response.use((response) => {
        return response
    }, (error) => {
        if (error.response.status === 403 || error.response.status === 401){
            localStorage.removeItem('isLoggedIn')
            window.location.href = loginAppUrl
        }
        if (error.response.status === 404 ){
            localStorage.removeItem('isLoggedIn')
            window.location.href = loginAppUrl
          }
        return Promise.reject(error)

    })
);


export default axiosIns
