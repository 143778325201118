export default [
    {
        path: '/programmes/:id',
        component: () => import('@/views/programmes/list/view/program-view'),
        name: 'programmes.single',
        meta: {
            noAuth: true,
            title: 'Service Clinics',
        },
    },
    {
        path: '/programmes',
        name: 'programmes',
        component: () => import('@/views/programmes/list/program-list.vue'),
        meta: {
            noAuth: true,
            title: 'Service Clinics',
        },
    }
]
