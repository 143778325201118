import profile from '@/apis/modules/profile'
import Cookies from 'js-cookie'
import router from '@/router/index'

export default {
    state: {
        current_user_data: null,
        current_user_permission: null,
        all_practice: null,
        all_pcn: null
    },
    mutations: {
        SET_CURRENT_USER: (state, value) => {
            state.current_user_data = value
            // Cookies.set('current_user_token', value.token)
        },
        CLEAR_CURRENT_USER: state => {
            state.current_user_data = undefined
            state.current_user_permission = undefined
            Cookies.remove('current_user_token')
        },
        SET_CURRENT_USER_PERMISSION: (state, value) => {
            state.current_user_permission = value
        },
        // SET_CURRENT_USER: (state, value) => {
        //   state.current_user_data = value
        //   Cookies.set('current_user_token', value.token)
        // },
        UPDATE_USER_INFO: (state, {displayName, photoURL}) => {
            state.current_user_data.name = displayName
            state.current_user_data.image = photoURL
        },
        SET_PRACTICE: (state, value) => {
            state.all_practice = value
        },
        SET_PCN: (state, value) => {
            state.all_pcn = value
        },
    },
    getters: {
        isLogedIn: state => state.current_user_data !== undefined && state.current_user_data !== null,
        currentUser: state => state.current_user_data,
        permissions: state => state.current_user_permission,
        getPracticeAndNetwork: state => state.all_practice,
        getPcnAndNetwork: state => state.all_pcn,
    },
    actions: {
        setCurrentUser: ({commit}, payload) => {
            commit('SET_CURRENT_USER', payload)
        },
        async autoLogin({commit}) {
            try {
                const cacheUserData = (await profile.getCurrentUser()).data.data
                commit('SET_CURRENT_USER', {
                    user: cacheUserData,
                })

                    const practiceData = (await profile.getAllPractice()).data.data
                    const pcnData = (await profile.getAllPcn()).data.data
                    commit('SET_PRACTICE', practiceData)
                    commit('SET_PCN', pcnData)

                    // commit('UPDATE_USER_INFO', {
                    //     displayName: cacheUserData.name,
                    //     photoURL: cacheUserData.photo_url,
                    // })

                // const cacheUserToken = Cookies.get('current_user_token')

                // await this.dispatch('afterLogin', true)
            } catch (e) {
                // console.log(e)
                await this.dispatch('logout')
            }
        },
        async login({commit}, payload) {
            // const loginData = (await profile.login(payload)).data
            // Cookies.set('current_user_time', new Date().getTime())
            // commit('SET_CURRENT_USER', loginData)
            // commit('UPDATE_USER_INFO', {
            //   displayName: loginData.data.first_name,
            //   photoURL: loginData.data.photo_url,
            // })
            // await this.dispatch('afterLogin')
            // await router.replace('/scheduler')
        },
        async logout({commit}) {
            try {
                await profile.logout()
            } catch (error) {
                await commit('CLEAR_CURRENT_USER')
            }
            await commit('CLEAR_CURRENT_USER')
        },
        async updateCurrentUserPhotoUrl({commit}, newUrl) {
            commit('UPDATE_USER_INFO', {
                photoURL: newUrl,
            })
        },
    },
}
