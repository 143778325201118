export default [
    {
        path: '/locations/:id',
        component: () => import('@/views/locations/list/view/location-view.vue'),
        name: 'locations.single',
        meta: {
            noAuth: true,
            title: 'Locations',
        },
    },
    {
        path: '/locations',
        name: 'locations',
        component: () => import('@/views/locations/list/location-list.vue'),
        meta: {
            noAuth: true,
            title: 'Locations',
        }
    }
]
