export default [
  {
    path: '/networks/:id',
    name: 'networks.single',
    component: () => import('@/views/networks/views/network-view.vue'),
    meta: {
      noAuth: true,
      title: 'Network Practices',
    },
  },
  {
    path: '/networks',
    name: 'networks',
    component: () => import('@/views/networks/index.vue'),
    meta: {
      noAuth: true,
      title: 'Network Practices',
    },
  },
];
