export default {
  init(router, store) {
    router.beforeEach(async (to, _, next) => {



      //   if (!canNavigate(to)) {
      //     if (!isLoggedIn) return next({ name: 'auth-login' })
      //     return next({ name: 'misc-not-authorized' })
      //   }
      if (!sessionStorage.getItem('auth_pcn_id')){
        sessionStorage.setItem('auth_pcn_id', to.query.auth_pcn_id)

      }
      const {currentUser} = store.getters
      if (currentUser === null){
        await store.dispatch('autoLogin')
      }


      const { isLogedIn } = store.getters

      if (to.meta.redirectIfLoggedIn && isLogedIn) {
        next({
          path: '/dashboard',
          replace: true,
        })
      }

      if (!to.meta.noAuth && !isLogedIn) {
        next({
          path: '/',
          replace: true,
        })
      }

      return next()
    })
  },
}
