import Vue from 'vue'
import {ModalPlugin, ToastPlugin} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import authController from '@/router/auth_controller'
import {SchedulePlugin} from '@syncfusion/ej2-vue-schedule'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'
// calendar
// eslint-disable-next-line import/order
// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/vue-mixins'
import '@/libs/vue-lodash'
import '@/libs/vee-validation'
import '@/libs/vue-select'
import '@/libs/sweet-alerts'

// Vue sheduler
//Common mixins
import MessagesMixin from "@/libs/mixins/MessagesMixin";
import CommonMixin from '@/mixins/CommonMixin'

import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { numeric, required, email, min, max } from 'vee-validate/dist/rules';

extend('numeric',numeric);

extend('min_service_charge',{
    validate: (value) => value < 0,
    message:'Service charge is invalid',
});
extend('max',{
    validate: (value) => value<=50000,
    message:'The Service charge must not be greater than 50000',
});
extend('min_address', {
    validate: (value) => value.length > 3,
    message: 'The Address field must contain at least 3 characters',
});

extend('emailTest', {
    validate: (value) => value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g),
    message: 'Please enter valid email address',
});

extend('tel', {
    validate: (value) => value.match(/^[0-9,+ ]*$/u),
    message: 'Telephone Number must not contain any letter or special character',
});
extend('min_tel', {
    validate: (value) => value.length > 6,
    message: 'Contact number must contain at least 7 numbers',
});
extend('max_tel', {
    validate: (value) => value.length < 17,
    message: 'Contact number allowed only 16 numbers ',
});

extend('min_sort_code', {
    validate: (value) => value.length > 4,
    message: 'Sort Code must contain at least 4 characters',
});
extend('max_sort_code', {
    validate: (value) => value.length < 10,
    message: 'Sort code allowed only 10 characters ',
});

// extend('postal_code', {
//     validate: (value) => value.match(/^([A-Z]){1}([0-9][0-9]|[0-9]|[A-Z][0-9][A-Z]|[A-Z][0-9][0-9]|[A-Z][0-9]|[0-9][A-Z]){1}([ ])?([0-9][A-z][A-z]){1}$/i)
//       || value.match(/^([A-Z]){1}([0-9][0-9]|[0-9]|[A-Z][0-9][A-Z]|[A-Z][0-9][0-9]|[A-Z][0-9]|[0-9][A-Z]){1}([ ])?([0-9][A-z][A-z]){1}$/i),
//     message: 'Please enter valid postal code ',
// })

extend('postal_code', {
    validate: (value) => value.match(/^((?:(?:gir)|(?:[a-pr-uwyz])(?:(?:[0-9](?:[a-hjkpstuw]|[0-9])?)|(?:[a-hk-y][0-9](?:[0-9]|[abehmnprv-y])?)))) ?([0-9][abd-hjlnp-uw-z]{2})\b/i),
    message: 'Please enter valid postal code ',
});

extend('hourly_rate', {
    validate: (value) => value > 0,
    message: 'Sort code allowed only 10 characters ',
});

extend('name_length', {
    validate: (value) => value.length <= 15,
    message: 'This field can not contain more than 15 Characters',
});

extend('pcn_name_length', {
    validate: (value) => value.length <= 25,
    message: 'This field can not contain more than 25 Characters',
});

extend('pcn_address_length', {
    validate: (value) => value.length <= 35,
    message: 'This field can not contain more than 35 Characters',
});

extend('address', {
    validate: (value) => value.length >= 3,
    message: 'The Address must be at least 3 characters.',
});




Vue.use(SchedulePlugin)
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

authController.init(router, store)

Vue.mixin({
    mixins: [CommonMixin ,MessagesMixin]
})

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
