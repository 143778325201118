import moment from 'moment'
import config from '@/constants/config'


export default {
  methods: {
    totalShiftCost(time , rate) {
      if (!time) return;
      const formatted_price = time*rate ;
      if (formatted_price<=0) return 0.00
      if (isNaN(formatted_price)) return 0;
      return formatted_price.toFixed(2);
    },
    // totalShiftCost (start, end, rate) {
    //
    //   if (!rate) return
    //   // moment(new Date((date+  ` ` + this.start))
    //
    //   var date = '2017-03-13'
    //   start = moment(date + ' ' + start)
    //   end = moment(date + ' ' + end)
    //   console.log(start)
    //   const formatted_price = ((moment(end).diff(moment(start), 'minutes')) / 60) * rate
    //   if (isNaN(formatted_price)) return 0
    //   return formatted_price.toFixed(2)
    // },

    timeDifferentHours (start, end, brek) {
      var date = '2017-03-13'
      start = moment(date + ' ' + start)
      end = moment(date + ' ' + end)
      brek = brek/60
      if(brek){
        const timeDef = moment(end).diff(moment(start), 'minutes') / 60;
        const timeDefWithBreak = (timeDef) - (brek)
        if (isNaN(timeDefWithBreak)) return 0;
        return timeDefWithBreak.toFixed(2);
      }
      const timeDef = ((moment(end).diff(moment(start), 'minutes')) / 60)
      if (isNaN(timeDef)) return 0
      return timeDef.toFixed(2)
    },


    calculateHoursWorked (shift) {
      if (!shift) return 0
      var total = 0
      shift.forEach((val) => {

        total += ((moment(val.end).diff(moment(val.start), 'minutes')) / 60)

      })
      return total

    },

    getUserImage (id, update = false) {
      return `${config.apiUrl}api/profile/user/${id}/image?${update ? Date.now() : ''}`
    },

    getWeekDayNumberViaDate (day) {
      let split = day.times[0].start.split(':')
      let weekDay =  moment(moment().startOf('week').add(day.day_number, 'days').set({
        h: split[0],
        m: split[1]
      })).utc().day()
      return weekDay === 0 ? 7 : weekDay
    },
    getUTCConvertedTime (day_number, time) {
      let split = time.split(':')
      console.log(split)
      return  moment(moment().startOf('week').add(day_number, 'days').set({
        h: split[0],
        m: split[1]
      })).utc().format('HH:mm:ss')
    },
    getFullName (user) {
      return user.first_name + ' ' + user.last_name
    },

    paymentUserHours(shifts){
      if (shifts.length === 0) return 0
      let total_hours = 0
      shifts.forEach((o, i) => {
        total_hours = total_hours+ moment(o.time_sheet.end).diff(moment(o.time_sheet.start), 'minutes') / 60;

      });
      return total_hours
    },

    paymentUserTotalEarn(shifts){
      if (shifts.length === 0) return {
        total:0.00,
        min_rate:0.00,
        max_rate:0.00
      }
      let total_amount = 0
      let min_rate = 100000000
      let max_rate = 0
      shifts.forEach((o, i) => {
        if (min_rate> o.salary_staff_rate){
          min_rate = o.salary_staff_rate
        }
        if (max_rate<o.salary_staff_rate){
          max_rate = o.salary_staff_rate
        }
        total_amount = total_amount+ (moment(o.time_sheet.end).diff(moment(o.time_sheet.start), 'minutes') / 60) * o.salary_staff_rate;

      });
      return {
        total:total_amount.toFixed(2),
        min_rate:min_rate.toFixed(2),
        max_rate:max_rate.toFixed(2)
      }
    },

    getStatus(date){
      var today = new Date().toLocaleDateString();
      var todayDate = new Date(today)
      var shiftStartDate = new Date(date)
      if(shiftStartDate < todayDate){
        return 'Past'
      }else if(shiftStartDate > todayDate){
        return 'Upcoming'
      }else{
        return 'Active'
      }

    },


  }
}
